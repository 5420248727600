/* tslint:disable */
/* eslint-disable */
/**
 * Groenhart API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.47
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdatePasswordApiRequestModel
 */
export interface UpdatePasswordApiRequestModel {
    /**
     * 
     * @type {string}
     * @memberof UpdatePasswordApiRequestModel
     */
    oldPassword?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdatePasswordApiRequestModel
     */
    newPassword?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdatePasswordApiRequestModel
     */
    confirmNewPassword?: string | null;
}

/**
 * Check if a given object implements the UpdatePasswordApiRequestModel interface.
 */
export function instanceOfUpdatePasswordApiRequestModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdatePasswordApiRequestModelFromJSON(json: any): UpdatePasswordApiRequestModel {
    return UpdatePasswordApiRequestModelFromJSONTyped(json, false);
}

export function UpdatePasswordApiRequestModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdatePasswordApiRequestModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'oldPassword': !exists(json, 'oldPassword') ? undefined : json['oldPassword'],
        'newPassword': !exists(json, 'newPassword') ? undefined : json['newPassword'],
        'confirmNewPassword': !exists(json, 'confirmNewPassword') ? undefined : json['confirmNewPassword'],
    };
}

export function UpdatePasswordApiRequestModelToJSON(value?: UpdatePasswordApiRequestModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'oldPassword': value.oldPassword,
        'newPassword': value.newPassword,
        'confirmNewPassword': value.confirmNewPassword,
    };
}

